const NordRedBox2 = () => (
    <svg width="240" height="240" viewBox="0 0 240 240" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g clipPath="url(#a6f4xma2ua)">
            <path d="M240 0H0v240h240V0z" fill="#8C1A11" />
            <path d="M135.824 155.338h-7.902v46.744h7.902v-46.744z" fill="#fff" />
            <mask id="shi14rzr0b" style={{ maskType: "luminance" }} maskUnits="userSpaceOnUse" x="25" y="145" width="202" height="58">
                <path d="M226.849 145.037H25.199v57.045h201.65v-57.045z" fill="#fff" />
            </mask>
            <g mask="url(#shi14rzr0b)" fill="#fff">
                <path d="M134.374 161.08c4.959-4.109 9.043-5.625 15.481-5.742 6.955-.125 11.507 1.52 16.772 6.064l-3.87 6.935c-3.193-4.355-7.019-6.241-12.418-6.129-7.898.166-14.663 6.278-14.663 13.974l-1.302-15.102zM204.041 145.037H196.3v57.044h7.741v-57.044zM42.224 179.621v22.464h-7.902v-46.743h7.902l-.044 6.785c4.632-4.898 9.583-6.862 16.655-6.785 11.506.121 20.501 7.785 22.473 19.126v27.617h-7.153V177.21c-.987-8.273-7.636-14.172-15.965-14.192-9.297-.024-15.728 6.661-16.05 17.627l.084-1.02v-.004z" />
                <path fillRule="evenodd" clipRule="evenodd" d="M81.247 178.577c0-12.87 10.434-23.304 23.303-23.304 12.87 0 23.304 10.434 23.304 23.304 0 12.869-10.434 23.303-23.304 23.303-12.869 0-23.303-10.434-23.303-23.303zm7.257 0c0-8.862 7.185-16.047 16.046-16.047 8.862 0 16.047 7.185 16.047 16.047 0 8.861-7.185 16.046-16.047 16.046-8.861 0-16.046-7.185-16.046-16.046zM156.466 178.641c0-12.869 10.435-23.303 23.304-23.303 12.869 0 23.303 10.434 23.303 23.303 0 12.869-10.434 23.303-23.303 23.303-12.869 0-23.304-10.434-23.304-23.303zm7.258 0c0-8.862 7.184-16.046 16.046-16.046 8.862 0 16.046 7.184 16.046 16.046 0 8.862-7.184 16.046-16.046 16.046-8.862 0-16.046-7.184-16.046-16.046zM211.963 149.73v-1.681h1.198c.504-.024 1.121.101 1.201.717-.076.908-.705.96-1.439.96h-.96v.004zm0 .718h.96l1.439 2.398h.96l-1.682-2.398c.896-.154 1.512-.585 1.44-1.682.072-.971-.569-1.447-1.919-1.439H211v5.519h.959v-2.398h.004zm-2.878-.48c0-2.169 1.697-3.871 3.838-3.838 2.193-.033 3.894 1.669 3.838 3.838.056 2.379-1.645 4.068-3.838 4.08-2.141-.012-3.842-1.701-3.838-4.08zm3.838 4.798c2.633.06 4.85-1.952 4.798-4.798.052-2.649-2.165-4.665-4.798-4.56-2.58-.105-4.798 1.911-4.798 4.56 0 2.846 2.218 4.858 4.798 4.798z" />
            </g>
        </g>
        <defs>
            <clipPath id="a6f4xma2ua">
                <path fill="#fff" d="M0 0h240v240H0z" />
            </clipPath>
        </defs>
    </svg>
)

export default NordRedBox2;